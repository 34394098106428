<template>
  <el-col
    v-if="esmobil"
    class="pz-large-margin text-lg-center"
  >
      <el-col
          class="row justify-content-center"
          :span="24"
      >
          <pz-button
              v-if="continuar"
              key="send"
              type="primary"
              :button-name="$t('continue')"
              @click="handleSend"
          />
      </el-col>
    <iframe
      id="naat"
      frameborder="0"
      width="100%"
      :height="height"
      class="pz-large-margin"
      :src="frame_route"
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts"
      allow="geolocation; microphone; camera; fullscreen"
    />
  </el-col>

  <el-col
    v-else
    class="pz-large-margin text-lg-center"
  >
    <pz-title>
      <span slot="title">
        Escanear Codigo QR para continuar el proceso en su Mobile <br>
      </span>
    </pz-title>
    <el-row :gutter="20">
      <el-col
        :xl="{span: 24}"
        :lg="{span: 24}"
        :md="{span: 24}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <vue-qrcode
          :value="url"
          :options="{ width: 200 }"
        />
      </el-col>
    </el-row>
  </el-col>
</template>

<script>

import {mapGetters} from "vuex";
import {isEmpty, isMobile} from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {setNaat,getDocsSign,createValidationNaat,saveValidationNaat,getCredencialesNaat,saveFaceNaat} from "@/routes/api/views/vales";
import PzButton from "@/commons/forms/Buttons/PzButton";
import {Objeto} from "@/commons/forms/DigitalSings/Fad/EventoNaat";
import {CONFIGURATIONFront,CONFIGURATIONReverse, CONFIGURATIONFace}
from "@/commons/forms/DigitalSings/Fad/Configuracion";


export default {
    name: "PzFad",
    components: {VueQrcode,PzButton},
    props: {
        route: {
            require: true,
            default: null,
            type: [String, URL]
        },
        redirect: {
            require: true,
            default: 'Prestamos',
            type: [String]
        },
    },
    data() {
        return {
            frame_route: this.route,
            tipo: 1,
            intentos: 1,
            side: 0,
            imageId: '',
            imageFace: '',
            ocr: '',
            url: window.location.href,
            height: '600px',
            watch: {
                route(value) {
                    this.frame_route = value;
                },
                frame_route(value) {
                    console.log('change form route');
                    console.log(value);
                },

            },
            continuar: false,
            moduleParams : {
                credentials: '',
                configuration: CONFIGURATIONFront,
                side: 0, // 0 - front id, 1 - back id
                idData: false, // true - ocr, false - without this data
                idPhoto: false, // true - get imaghen face of id, false - without this data
                imageQuality: 0.5, // quality of image id, range 0 - 1
            },
            CREDENTIALSAcuant:'',
            CREDENTIALSFace:'',
            sharpnessThreshold:'55',
            middleware:false

        }
    },
    computed: {
        ...mapGetters(['alias']),
        esmobil(){
            return isMobile()
        }
    },

    created() {
        this.getCredenciales();
        window.addEventListener("message", this.eventos, false);
    },
    methods: {
        eventos(event) {
    if(event.data)
        switch (event.data.event) {
            case 'MODULE_READY':
                console.log('MODULE_READY');
                if(this.tipo==1 )
                    this.initModule();
                else if(this.tipo==2 )
                    this.initModuleFacetec();
                break;
            case 'PROCESS_ERROR':
                console.log('error');

                if(this.intentos<3) {
                    this.$store.dispatch('app/setMessage', 'Nuevo intento para la captura de información');
                    this.intentos =  this.intentos +1;
                    if(this.tipo==1)
                        this.initIframe();
                    else if(this.tipo==2)
                        this.initModuleFacetec();
                    else this.initModuleFirma();
                }else{

                    this.handleRechazo();
                }
                break;
            case 'PROCESS_COMPLETED':
                console.log("Process completed");
                var result = event.data.data;
                console.log(result)

                if(this.tipo==1){
                    if(this.side==0){
                        sessionStorage.setItem('documentInstance', result.documentInstance);
                        sessionStorage.setItem("idFront", result.id.image.data);
                        this.side = 1;
                        this.initModuleReverse();
                    }else {
                        this.crearValidacion({
                            'imageFront':sessionStorage.getItem("idFront"),
                            'imageBack': result.id.image.data,
                            'ocr': result.idData.ocr,
                            'foto': result.idPhoto, 'alerts': result.idData.alerts
                        });
                    }
                }else if(this.tipo==2){
                    this.saveFace({
                        'imgLowQuality':result.lowQualityAuditTrail[0]
                    });
                }
                else{
                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar= true;
                        this.loading = false;
                        this.handleSend();
                    });
                }
                break;
            case 'PROCESS_INIT':
                console.log("Process init");
                break;
            case 'PROCESS_ENDED':
                if(this.tipo==3) {
                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar = true;
                        this.loading = false;
                        this.handleSend();
                    });
                    console.log('proceso terminado firma');
                }
                break;
            case 'SIGNATURE_COMPLETED':
                if(this.tipo==3) {
                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar = true;
                        this.loading = false;
                        this.handleSend();
                    });
                    console.log('proceso de firma completo');
                }
                break;
            default:
                return;

        }
        },
        async getCredenciales(){
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            await getCredencialesNaat().
            then((res) => {
                 this.CREDENTIALSAcuant = res.data.CREDENTIALSAcuant;
                 this.CREDENTIALSFace = res.data.CREDENTIALSFace;
                 this.moduleParams.credentials = res.data.CREDENTIALSAcuant;
                 this.sharpnessThreshold = res.data.sharpnessThreshold;
                 this.moduleParams.configuration.sharpnessThreshold =  this.sharpnessThreshold;
                 this.middleware =  res.data.middleware;
            }).
            catch((error) => {
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
         initIframe() {
            const iframe = document.getElementById("naat");
            iframe.src = this.frame_route;
        },
        handleSend() {
            console.log('handleSend');
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$router.push({name: 'FinFirma'});
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        handleRechazo() {
            console.log('handleRechazo');
            let motivo = this.tipo==1?this.$t('motivo_rechazo.acuant'):this.$t('motivo_rechazo.facetec')
            this.tipo=1;
            this.intentos=1;
            this.side=0;
            window.removeEventListener("message", this.eventos, false);
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$router.push({name: 'FadRechazo', params: {mensaje: motivo}});
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        initModule() {
            const iframe = document.getElementById("naat");
            console.log(this.moduleParams)
            iframe.contentWindow.postMessage(
                Objeto("INIT_MODULE",this.moduleParams), iframe.src);

        },
        initModuleReverse() {
            console.log('initModuleReverse ');
            console.log(sessionStorage.getItem('documentInstance'));
            this.moduleParams.documentInstance = sessionStorage.getItem('documentInstance');
            this.moduleParams.side = 1;
            this.moduleParams.idData= true;
            this.moduleParams.idPhoto=true;
            this.moduleParams.credentials= this.CREDENTIALSAcuant;
            this.moduleParams.configuration= CONFIGURATIONReverse;
            this.moduleParams.configuration.sharpnessThreshold =  this.sharpnessThreshold;
            setTimeout( () => this.initIframe(), 0);

        },
        initModuleFacetec() {
            console.log('initModuleFacetec ');
            const iframe = document.getElementById("naat");
            const CREDENTIALS = this.CREDENTIALSFace;
            const CONFIGURATION = CONFIGURATIONFace;
            var dataConfiguracion ={};
            if(this.middleware)
                 dataConfiguracion  = {
                    middleware: CREDENTIALS,
                    configuration: CONFIGURATION,
                }
            else{
                dataConfiguracion  = {
                    credentials: CREDENTIALS,
                    configuration: CONFIGURATION,
                }
            }
            console.log(dataConfiguracion);
                iframe.contentWindow.postMessage(
                    Objeto("INIT_MODULE", dataConfiguracion),
                    iframe.src
                );
        },

        initModuleFirma() {
            console.log('initModuleFirma ');
            this.getRoute(3);
            this.tipo=3;
            this.intentos = 1;
            const iframe = document.getElementById("naat");
            iframe.src = this.frame_route;
        },

        async getRoute(tipo=2){
             console.log(this.$route.params);
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await getDocsSign({'loan':this.$route.params.loan,'tipo':tipo}).
            then((res) => {
                if (res.data.valid && !isEmpty(res.data.ruta)) {
                    this.frame_route = res.data.ruta;
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },

        async crearValidacion(result){
            console.log(this.$route.params);
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await createValidationNaat(this.$route.params.loan,result).
            then((res) => {
                if (!res.data.valid ) {
                    this.$store.dispatch('app/catchErrors', {_this: this, error: 'Problemas registro naat '});
                }else{
                    this.saveValidation();
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
        async saveValidation(){
            console.log('saveValidationNaat');
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await saveValidationNaat(this.$route.params.loan).
            then((res) => {
               if (!res.data.valid ) {
                    this.handleRechazo();

                }else{
                    this.getRoute();
                    this.tipo=2;
                    this.intentos = 1;
                    const iframe = document.getElementById("naat");
                    iframe.src = this.frame_route;
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
        async saveFace(result){
            console.log('saveFace');
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await saveFaceNaat(this.$route.params.loan,result).
            then((res) => {
                if (!res.data.valid ) {
                    this.handleRechazo();
                }else{
                    this.initModuleFirma();
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        }
    },

}
</script>
