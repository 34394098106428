class ResponseEvent {
    event;
    data;
    constructor(event, data) {
        this.event = event;
        this.data = data;
    }
}

export function Objeto(event,data){
    return new ResponseEvent(event,data);
}